<!-- 话费充值方式显示 -->
<template>
  <div>
    <div v-if="false" class="font-size32 font-color1B1B1B font-weightBold line-height44 margin-top40">
      储存周期
    </div>
    <div class="grid margin-top40">
      <div @click="clickFunctionType(item,index)" v-for="(item,index) in rechargeMethodObject.gridDataType"
           :key="item.id"
           class="item">
        <div
            class="itemNotSelectType flex_center flex-directionColumn positionRelative"
            :class="{ 'itemSelectType': rechargeMethodObject.selectIndexType === index }">
          <div class="flex_center">
            <img v-if="false" style="height: var(--24);"
                 :src=item.image alt="">
            <div v-if="!item.isInMaintenance" class="font-size30 line-height42 margin-left10">{{ item.text }}</div>
            <div v-if="item.isInMaintenance" class="font-color999999">
              <div class="font-size26 margin-left10">{{ item.text }}</div>
              <div class="font-size20 margin-left10">{{ item.textDetail }}</div>
            </div>
          </div>
          <img v-if="rechargeMethodObject.selectIndexType === index" alt="" class="positionAbsolute"
               src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/60f626d6-9c53-44ef-94c4-ed229a915eff.png"
               style="width: var(--62);height: var(--44);
                right: var(---2);bottom: var(---2);">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rechargeMethodObject: {
      selectIndexType: 0,
      gridDataType: []
    }
  },
  data() {
    return {}
  },
  methods: {
    clickFunctionType(item, index) {
      this.rechargeMethodObject.selectIndexType = index
      this.$emit('handleChange')
    },
  }
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: auto auto;
  /*grid-template-rows: 380px; 设置行高*/
  /*grid-gap: var(--18);*/
  row-gap: var(--40); /* 上下方向的间距为10像素 */
  column-gap: var(--30); /* 左右方向的间距为20像素 */
}

.itemNotSelectType {
  /*width: var(--218);*/
  height: var(--84);
  background: #FFFFFF;
  border-radius: var(--10);
  border: var(--2) solid #EEEEEE;
  color: #1B1B1B;
}

.itemSelectType {
  border: var(--2) solid #026AFC;
  color: #026AFC;
}
</style>
