<!-- 订单金额 -->
<template>
  <div class="itemClass flex_able_1 flex-directionColumn">
    <span class="font-size36 font-weightBold font-color1B1B1B line-height50">
      总结
    </span>
    <div v-for="(item, index) in itemList" :key="index">
      <div>
        <div class="itemInnerClass flex_able" @click="copyContent(item)">
          <span class="flex_1 font-size28 font-color1B1B1B line-height40">{{ item.title }}</span>
          <div :style="{fontSize:item.textSize,color:item.rightTextColor,fontWeight:item.bold}">
            <span>{{ item.left }}</span>
            <span>{{ item.amount }}</span>
            <span>{{ item.right }}</span>
          </div>
          <div v-if="item.copy" class="flex_able">
            <div class="copyLineClass margin-left10 margin-right10"></div>
            <span class="font-size28 font-color1B1B1B line-height40">复制</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import {Toast} from "vant";

export default {
  props: {
    title: {},
    itemList: {}
  },
  data() {
    return {}
  },
  methods: {
    copyContent(item) {
      if (!item.copy) {
        return
      }
      let copyString = item.amount
      //如果是安卓环境  使用安卓的复制方法
      if (window.Android) {
        window.Android.copyText(copyString)
      } else {
        //复制口令
        let clipboard3 = new Clipboard(".itemInnerClass", {
          text: function () {
            return copyString
          }
        });
        clipboard3.on('success', function () {//复制成功执行的回调，可选
          Toast("复制成功");
        });
        clipboard3.on('error', function () {//复制失败执行的回调，可选
          Toast("复制失败");
        })
      }
    }
  }
}
</script>

<style scoped>
.itemClass {
  width: 100vw;
  padding: var(--30) var(--30) 0 var(--30);
}

.itemInnerClass {
  height: var(--80);
}

.copyLineClass {
  width: var(--2);
  height: var(--18);
  background: #D8D8D8;
  border-radius: var(--1);
}

</style>
