<template>
  <div class="body">
    <div
        class="flex_able margin-top40 margin-right30 line-height42 font-size30 font-color1B1B1B margin-left30">
      <span class="font-weightBold" style="flex: 1">储存数量</span>
      <span @click="openTopUpPowerRecord" class="font-size30" style="color: #268DFF">储存记录</span>
    </div>
    <div class="flex_able"
         style="background:#FAFAFA;margin-top: var(--30);border-radius: var(--24);height: var(--112);
        margin-left: var(--30);margin-right: var(--30);padding-left: var(--30);padding-right: var(--30);">
      <van-field style="flex:1;margin-right: var(--30);" v-model="addressValue" :placeholder=minAmountStr clearable/>
      <span class="font-size28 font-color1B1B1B line-height40">{{ Constants.COIN_X }}</span>
    </div>
    <div v-if="errorMsg.length > 0" style="color:#F35757;margin-top: var(--18);"
         class="font-size26 line-height36 margin-left30">
      {{ errorMsg }}
    </div>
    <div class="flex_able margin-top20 margin-right30">
      <div class="margin-left30 font-size28" style="color:#7E90A0;">
        账户余额
      </div>
      <div class="font-size30 font-weightBold font-color026AFC margin-left22">
        {{ EncryptUtils.formatDecimal2NoZero(balanceX) }} {{ Constants.COIN_X }}
      </div>
    </div>
    <div class="itemTime margin-leftAuto margin-rightAuto margin-top30">
      <div class="margin-left30 margin-top48 flex_able">
        <img style="width: var(--32);height: var(--34);"
             src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/347064b5-603e-4f7e-97b8-4b0cf52a5af3.png">
        <div class="font-size30 font-weightBold line-height42 margin-left14">
          储存日期
        </div>
      </div>
      <store-star-diamond-time style="padding-left: var(--30);padding-right: var(--30);"
                               :recharge-method-object="rechargeMethodObjectTime"
                               @handleChange="handleChange"/>
      <div class="dayClass margin-top40 margin-left30 flex_able">
        <div class="font-size30 font-color026AFC line-height42 margin-left30" style="flex: 1">
          每日火箭值产出数量
        </div>
        <div class="font-size30 font-weightBold font-color026AFC margin-right30">
          {{ getDailyAmount }}
        </div>
      </div>
    </div>
    <coin-pay-info
        :title="otcOrderPayInfoTitle"
        :item-list="otcOrderPayInfoItemList"/>
    <div class="flex_center" style="margin-top: var(--20)">
      <div @click="goToStoreRecord" class="flex_center margin-left30"
           style="width: var(--690);height: var(--88);
          border-radius: var(--44);font-size: var(--30);color: #268DFF;border: var(--1) solid #268DFF;">
        储存收益
      </div>
      <div @click="submitInfoAmount" class="flex_center margin-right30 margin-left30"
           :style="{'background': (isEmpty(errorMsg) && !isEmpty(addressValue)) ? '#268DFF' : '#BEDDFF'}"
           style="width: var(--690);height: var(--88);
          border-radius: var(--44);font-size: var(--30);font-weight: 400;color: #FFFFFF;">
        储存
      </div>
    </div>
    <div class="flex_center font-size24 font-color999999 margin-top30 margin-left20 margin-right20">储存期间内不能取出，等待储存结束后X币会自动发放至用户账户中</div>
  </div>
</template>

<script>
//开始1
import {Dialog, Toast} from 'vant';
import {Api} from '@/utils/net/Api';
import {Constants} from "@/utils/constants";
import EncryptUtils from "../../../utils/EncryptUtils";
import StoreStarDiamondTime from "@/pages/app/power/components/storeCoinXTime.vue";
import coinPayInfo from "@/pages/app/power/components/coinPayInfo.vue";
//开始2
export default {
  components: {StoreStarDiamondTime, coinPayInfo},
  computed: {
    Constants() {
      return Constants
    },
    EncryptUtils() {
      return EncryptUtils
    }
  },
  data() {
    //开始4
    //初始化数据
    return {
      timeNumber: 1,
      actionId: "",
      errorMsg: "",
      rewardRole: '',
      balanceX: 0,
      minAmount: 0,
      maxAmount: 0,
      minAmountStr: '',
      addressValue: '',
      pressTimer: {},
      rechargeMethodObjectTime: {
        selectIndexType: 0,
        gridDataType: [
          // {
          //   text: "90天",
          //   number: 90
          // }, {
          //   text: "180天",
          //   number: 180
          // }, {
          //   text: "270天",
          //   number: 270
          // }, {
          //   text: "360天",
          //   number: 3600
          // }
        ]
      },
      otcOrderPayInfoTitle: "",
      otcOrderPayInfoItemList: [
        {
          title: "储存开始时间",
          left: '',
          amount: '',
          right: "",
          copy: false,
          rightTextColor: '#999999',
          bold: 'normal',
          textSize: 'var(--28)',
        }, {
          title: "储存结束时间",
          left: '',
          amount: '',
          right: "",
          copy: false,
          rightTextColor: '#999999',
          bold: 'normal',
          textSize: 'var(--28)',
        }, {
          title: "储存时长",
          left: '',
          amount: '',
          right: "",
          copy: false,
          rightTextColor: '#1b1b1b',
          bold: 'normal',
          textSize: 'var(--28)',
        }, {
          title: "储存每日火箭值产出数",
          left: '',
          amount: '',
          right: "",
          copy: false,
          rightTextColor: '#026AFC',
          bold: 'normal',
          textSize: 'var(--28)',
        }
      ],
      getDailyAmount: 0
    }
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = "储存"
    } catch (error) {
      console.log("常规错误:" + error)
    }
    this.queryStakeAmountList()
  },
  watch: {
    addressValue(val, newVal) {
      console.log("val" + val)
      console.log("newVal" + newVal)
      if (val < this.minAmount) {
        this.errorMsg = "最小起存数量为" + this.minAmount + "X币"
      } else {
        this.errorMsg = ""
      }
      if (EncryptUtils.isEmpty(val)) {
        this.errorMsg = ""
      }
      console.log(this.rate)
      console.log(this.balanceX)
      this.handleChange()
      console.log(this.needPayAmount)
    }
  },
  methods: {
    queryStakeAmountList() {
      let params = {}
      Api.queryStakeAmountList(params).then((result) => {
        if (result.code === 100) {
          this.rechargeMethodObjectTime.gridDataType = []
          result.data.stakeAmounts.forEach((item) => {
            let itemObj = {}
            itemObj["text"] = item.time + "天"
            itemObj["number"] = item.time
            itemObj["amountDay"] = item.amountDay
            itemObj["id"] = item.id
            this.rechargeMethodObjectTime.gridDataType.push(itemObj)
          })
          this.minAmount = result.data.minAmount
          this.minAmountStr = "储存数量必须" + result.data.minAmount + "以上"
          this.balanceX = result.data.balance
          this.handleChange()
        } else {
          Toast(result.msg);
        }
      })
    },
    //显示总结
    showSellInfo() {
      let item = this.rechargeMethodObjectTime.gridDataType[this.rechargeMethodObjectTime.selectIndexType]
      this.otcOrderPayInfoTitle = "总结"
      this.otcOrderPayInfoItemList[0].amount = this.add90Days(0)

      this.otcOrderPayInfoItemList[1].amount = this.add90Days(item.number)

      this.otcOrderPayInfoItemList[2].amount = item.text

      if (EncryptUtils.isEmpty(this.addressValue)) {
        this.otcOrderPayInfoItemList[3].amount = 0
      } else {
        this.otcOrderPayInfoItemList[3].amount = EncryptUtils.formatDecimal2NoZero(item.amountDay * parseFloat(this.addressValue))
      }
    },
    add90Days(timeDay) {
      // 获取当前时间
      let currentDate = new Date();

      // 加上90天的毫秒数
      currentDate.setDate(currentDate.getDate() + timeDay);

      // 格式化日期时间为 "YYYY-MM-DD HH:mm:ss"
      return EncryptUtils.formatDateForDayAll(currentDate)
    },
    handleChange() {
      console.log("handleChange")
      let item = this.rechargeMethodObjectTime.gridDataType[this.rechargeMethodObjectTime.selectIndexType]
      console.log(JSON.stringify(item))
      if (EncryptUtils.isEmpty(this.addressValue)) {
        this.getDailyAmount = 0
      } else {
        this.getDailyAmount = EncryptUtils.formatDecimal2NoZero(item.amountDay * parseFloat(this.addressValue))
      }
      console.log(JSON.stringify(this.getDailyAmount))
      console.log(JSON.stringify(item.amountDay))
      console.log(JSON.stringify(this.addressValue))
      this.showSellInfo()
      // let numberNumber = this.addressValue
      // let numberTime = itemTime.number
      // //更新储存星钻
      // this.contentList1[0].number = numberNumber * this.numberCopies
      // //更新储存周期
      // this.contentList1[1].number = numberTime
      // //跟新份数
      // this.contentList1[2].number = this.numberCopies
      // this.stakeAmounts.forEach((item) => {
      //   if (item.amount == numberNumber && item.time == numberTime) {
      //     //结合份数计算
      //     let dailyIncome = item.amountDay
      //     //控制最大份数
      //     this.maxNumberCopies = item.maxLimit
      //     //更新共计日产量
      //     this.contentList2[0].number = EncryptUtils.formatDecimal2(dailyIncome * this.numberCopies)
      //     //更新共计获得星值
      //     this.contentList2[1].number = item.amountStar * this.numberCopies
      //     //更新选中后的id
      //     this.selectNumberId = item.id
      //   }
      // })
    },
    isEmpty(obj) {
      return typeof obj == "undefined" || obj == null || obj == "";
    },
    goToStoreRecord() {
      this.$router.push({
        path: '/rocketValue',
      });
    },
    openTopUpPowerRecord() {
      this.$router.push({
        path: '/storeCoinRecord',
      });
    },
    submitInfoAmount() {
      let show = EncryptUtils.isEmpty(this.errorMsg) && !EncryptUtils.isEmpty(this.addressValue)
      if (!show) {
        return
      }
      let item = this.rechargeMethodObjectTime.gridDataType[this.rechargeMethodObjectTime.selectIndexType]
      Dialog.confirm({
        title: "确认储存",
        message: '本次储存' + this.addressValue + Constants.COIN_X + '，储存周期为' + item.text + '，是否确定储存？',
        confirmButtonText: "确定",
        confirmButtonColor: "#026AFC",
        cancelButtonColor: "#666666",
        messageAlign: "left",
        getContainer: ".body"
      }).then(() => {
        this.queryStakeBuy()
      }).catch(() => {
        console.log('cancel');
      })
    },
    queryStakeBuy() {
      let params = {
        id: this.rechargeMethodObjectTime.gridDataType[this.rechargeMethodObjectTime.selectIndexType].id,
        amount: this.addressValue,
      }
      Api.queryStakeBuy(params).then((result) => {
        if (result.code === 100) {
          Toast("储存成功")
          let that = this
          setTimeout(() => {
            //刷新接口
            that.queryStakeAmountList()
            //跳转到我的储存
            that.openTopUpPowerRecord()
          }, 1000)
        } else {
          Toast(result.msg);
        }
      })
    },
  }
}
</script>

<style scoped>
.body {
  background: #ffffff;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* 设置背景和padding */
::v-deep .van-cell {
  background-color: transparent;
  padding: 0;
}

/* 设置 placeholder 颜色 */
::v-deep .van-field__control::placeholder {
  color: #7E90A0;
}

/* 输入框高度 增加热区 */
::v-deep .van-field__control {
  height: var(--112);
}

/* 设置field里面的字体大小 */
::v-deep .van-field__control {
  color: #1B1B1B;
  font-size: var(--28);
  font-weight: 400;
  line-height: var(--40);
}

::v-deep .van-cell::after {
  left: 0;
  border: none;
}

.participation_conditions_box_content {
  width: var(--692);
  line-height: 160%;
  font-size: var(--30);
  color: #C6C6C6;
}

.itemTime {
  width: var(--690);
  height: var(--522);
  background: #FAFAFA;
  border-radius: var(--24);
}

.dayClass {
  width: var(--630);
  height: var(--100);
  background: #EAF1FF;
  border-radius: var(--10);
}
</style>
